import axios from "axios";

const API_URL = localStorage.getItem('REACT_APP_API_URL') || process.env.REACT_APP_API_URL;

export const _getAllList = async () => {
  try {
    const url = `${API_URL}/jobs/all_lists`;
    const response = await axios.get(url);
    return response;
  } catch (error) {
    throw error;
  }
};

export const _getInterviews = async (page, size) => {
  try {
    const url = `${API_URL}/jobs/list/interviews?page=${page}&pageSize=${size}`;
    const response = await axios.get(url);
    return response;
  } catch (error) {
    throw error;
  }
};

export const _getEmployees = async (page, size) => {
  try {
    const url = `${API_URL}/jobs/employee_list?page=${page}&pageSize=${size}`;
    const response = await axios.get(url);
    return response;
  } catch (error) {
    throw error;
  }
};

export const _getSkill = async (id) => {
  try {
    const url = `${API_URL}/jobs/skill/${id}`;
    const response = await axios.get(url);
    return response;
  } catch (error) {
    throw error;
  }
}

export const _parseJD = async (data) => {
  try {
    let config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    const url = `${API_URL}/jobs/parse_jd`;
    const response = await axios.post(url, data, config);
    return response;
  } catch (error) {
    throw error;
  }
};


//_getHrData

export const _getHrData = async () => {
  try {
    const url = `${API_URL}/jobs/hr_dash_data`;
    const response = await axios.get(url);
    return response;
  } catch (error) {
    throw error;
  }
};

export const _getAll = async (page, size) => {
  try {
    const url =  `${API_URL}/jobs?page=${page}&pageSize=${size}`;
    const response = await axios.get(url);
    return response;
  } catch (error) {
    throw error;
  }
};

export const _getAllJobs = async () => {
  try {
    const url = `${API_URL}/jobs/all`;
    const response = await axios.get(url);
    return response;
  } catch (error) {
    throw error;
  }
};

export const _getAllBatches = async (id, page, size) => { 
  try {
    const url = `${API_URL}/jobs/batches/${id}?page=${page}&pageSize=${size}`;
    const response = await axios.get(url);
    return response;
  } catch (error) {
    throw error;
  }

}

export const _getJobById = async (id) => {
  try {
    const url = `${API_URL}/jobs/${id}`;
    const response = await axios.get(url);
    return response;
  } catch (error) {
    throw error;
  }
};

export const _applyJob = async (data) => {
  try {
    const url = `${API_URL}/jobs/apply`;
    const response = await axios.post(url, data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const _createJob = async (data) => {
  try {
   let config = {
     headers: {
       "content-type": "multipart/form-data",
     },
   };
    const url = `${API_URL}/jobs`;
     const response = await axios.post(url, data, config);
     return response;
    
    
  } catch (error) {
    throw error;
  }
};

export const _uploadCVs = async (data, id) => {
  try {
    let config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    const url = `${API_URL}/jobs/upload_cvs/${id}`;
    const response = await axios.post(url, data, config);
    return response;
  } catch (error) {
    throw error;
  }
};

export const _updateJob = async (id, data) => {
  try {
    const url = `${API_URL}/jobs/${id}`;
    const response = await axios.put(url, data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const _deleteJob = async (id) => {
  try {
    const url = `${API_URL}/jobs/${id}`;
    const response = await axios.delete(url);
    return response;
  } catch (error) {
    throw error;
  }
};
//_updateJobSkills

export const _updateJobStatus = async (id, data) => {
  try {
    const url = `${API_URL}/jobs/update_status/${id}`;
    const response = await axios.put(url, data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const _updateJobSkills = async (id, data) => {
  try {
    const url = `${API_URL}/jobs/update_skills/${id}`;
    const response = await axios.put(url, data);
    return response;
  } catch (error) {
    throw error;
  }
};

// export const _downloadJD = async (id, name, down) => {
//   try {
//     const url = `${API_URL}/jobs/download_jd/${id}`;
//     const response = await axios.get(url);
   
//     return response;
//   } catch (error) {
//     throw error;
//   }
// };

export const _downloadJD = async (JobDetail) => {
  try {
    const url = `${API_URL}/jobs/download_jd/${JobDetail.jb_id}`;
    const response = await axios.get(url, { responseType: "blob" });

    // Create a URL for the blob
    const blobUrl = window.URL.createObjectURL(new Blob([response.data]));

    // Create a temporary link element
    const link = document.createElement("a");
    link.href = blobUrl;
    link.setAttribute("download", JobDetail.jb_name+".pdf");

    // Append the link to the body
    document.body.appendChild(link);

    // Trigger the click event on the link
    link.click();

    // Remove the link from the body
    document.body.removeChild(link);

    return response;
  } catch (error) {
    throw error;
  }
};

export const _viewJD = async (id) => {
  try {
    const url = `${API_URL}/jobs/download_jd/${id}`;
    const response = await axios.get(url, { responseType: "arraybuffer" });

    // Create a Blob from the response data with content type 'application/pdf'
    const blob = new Blob([response.data], { type: "application/pdf" });

    // Create a URL for the blob
    const blobUrl = window.URL.createObjectURL(blob);

    // Open the blob URL in a new tab
    window.open(blobUrl);

    return response;
  } catch (error) {
    throw error;
  }
};


export const _getCourseList = async () => {
  try {
    const url = `${API_URL}/courses/all_course`;
    const response = await axios.get(url);
    return response;
  } catch (error) {
    throw error;
  }
};


export const _createAudioVideo = async (id, data) => {
  try {
    const url = `${API_URL}/uploads/${id}`;
    const response = await axios.post(url, data);
    return response;
  } catch (error) {
    throw error;
  }
};


