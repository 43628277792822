import axios from "axios";

const API_URL = localStorage.getItem('REACT_APP_API_URL') || process.env.REACT_APP_API_URL;

export const _loginCandidate = async (data) => {
  try {
    const login_url = `${API_URL}/candidates/sign_in`;
    const response = await axios.post(login_url, data);
    return response;
  } catch (error) {
    throw error;
  }
};

//_updateCandidate

export const _updateCandidate = async (data, id) => {
  try {
    const url = `${API_URL}/candidates/${id}`;
    const response = await axios.put(url, data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const _scheduleSecRound = async (data) => {
  try {
    const url = `${API_URL}/jobs/schedule/second-round`;
    const response = await axios.post(url, data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const _getCandDashboard = async (id) => {
  try {
    const dashboard_url = `${API_URL}/candidate/dashboard/${id}`;
    const response = await axios.get(dashboard_url);
    return response;
  } catch (error) {
    throw error;
  }
};

export const _startCandInterview = async (id) => {
  try {
    const start_interview_url = `${API_URL}/applicants/start_interview/${id}`;
    const response = await axios.get(start_interview_url);
    return response;
  } catch (error) {
    throw error;
  }
};

// _getInterviewStatus

export const _getInterviewStatus = async (id) => {
  try {
    const start_interview_url = `${API_URL}/applicants/status_interview/${id}`;
    const response = await axios.get(start_interview_url);
    return response;
  } catch (error) {
    throw error;
  }
};

export const _getJobsList = async (id) => {
  try {
    const _url = `${API_URL}/candidates/jobs/list`;
    const response = await axios.get(_url);
    return response;
  } catch (error) {
    throw error;
  }
};

export const _submitCandInterviewQuestion = async (data) => {
  try {
    let config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    const submit_interview_url = `${API_URL}/applicants/submit_interview_question`;
    const response = await axios.post(submit_interview_url, data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const _endCandInterview = async (data) => {
  try {
    const end_interview_url = `${API_URL}/applicants/conclude_interview`;
    const response = await axios.post(end_interview_url, data);
    return response;
  } catch (error) {
    throw error;
  }
};
//_getCandidatesByBatchId
export const _getCandidatesByBatchId = async (bt_id, page, size) => {
  try {
    const url = `${API_URL}/jobs/candidatesByBatchId/${bt_id}?page=${page}&pageSize=${size}`;
    const response = await axios.get(url);
    return response;
  } catch (error) {
    throw error;
  }
};
export const _getCandidates = async (jb_id, page, size, filters, search) => {
  try {
    let url = `${API_URL}/candidates/${jb_id}?page=${page}&pageSize=${size}`;
    if (filters) {
      url = url + filters;
    }
    if (search) {
      url = url + search;
    }

    const response = await axios.get(url);
    return response;
  } catch (error) {
    throw error;
  }
};

//_getCandidatesFilter

export const _getCandidatesFilter = async (jb_id, page, size, filters) => {
  try {
    const url = `${API_URL}/candidates/${jb_id}?page=${page}&pageSize=${size}`;
    const response = await axios.get(url);
    return response;
  } catch (error) {
    throw error;
  }
};

export const _getQAs = async (candidateId, threadId) => {
  try {
    const url = `${API_URL}/candidates/ques_ans/${candidateId}/${threadId}`;
    const response = await axios.get(url);
    return response;
  } catch (error) {
    throw error;
  }
};

export const _sendInvite = async (id, bodyData) => {
  try {
    const url = `${API_URL}/jobs/send_invite/${id}`;
    const response = await axios.post(url, bodyData);
    return response;
  } catch (error) {
    throw error;
  }
};

export const _submitAiAns = async (data) => {
  try {
    let config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    const url = `${API_URL}/candidates/audio`;
    const response = await axios.post(url, data, config);
    return response;
  } catch (error) {
    throw error;
  }
};

export const _endInt = async (data) => {
  try {
    const url = `${API_URL}/candidates/conclude`;
    const response = await axios.post(url, data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const _addHrRemarks = async (data) => {
  try {
    const url = `${API_URL}/candidates/add_remarks`;
    const response = await axios.post(url, data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const _releaseOffer = async (id) => {
  try {
    const url = `${API_URL}/candidates/release_offer/${id}`;
    const response = await axios.put(url);
    return response;
  } catch (error) {
    throw error;
  }
};
export const _giveUserConsent = async (id) => {
  try {
    const url = `${API_URL}/applicants/give_consent/${id}`;
    const response = await axios.put(url);
    return response;
  } catch (error) {
    throw error;
  }
};
