import axios from "axios";

const API_URL = localStorage.getItem('REACT_APP_API_URL') || process.env.REACT_APP_API_URL;

export const login = async (data) => {
  try {
    const login_url = `${API_URL}/user/sign_in`;
    const response = await axios.post(login_url, data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const _verifyOtp = async (data) => {
  try {
    const login_url = `${API_URL}/user/verify_otp`;
    const response = await axios.post(login_url, data);
    return response;
  } catch (error) {
    throw error;
  }
};


export const auth = async () => {
  try {
    const auth_user_url = `${API_URL}/user/auth_user`;
    const response = await axios.get(auth_user_url);
    return response;
  } catch (error) {
    throw error;
  }
};

export const signup = async (data) => { 
  try {
    const signup_url = `${API_URL}/user/sign_up`;
    const response = await axios.post(signup_url, data);
    return response;
  } catch (error) {
    throw error;
  }
}


export const logout = async() => {

   try {
    const signup_url = `${API_URL}/user/sign_out`;
    const response = await axios.delete(signup_url);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const forgotPassword = async (data) => { 
  try {
    const forgot_password_url = `${API_URL}/user/forgot_password`;
    const response = await axios.post(forgot_password_url, data);
    return response;
  } catch (error) {
    throw error;
  }
} 

export const resetPassword = async (data) => {
  try {
    const reset_password_url = `${API_URL}/user/reset_password`;
    const response = await axios.post(reset_password_url, data);
    return response;
  } catch (error) {
    throw error;
  }
}

export const verifyResetToken = async (data) => {
  try {
    const verify_reset_token_url = `${API_URL}/user/verify_reset_token`;
    const response = await axios.post(verify_reset_token_url, data);
    return response;
  } catch (error) {
    throw error;
  }
}


export const getuserprofile = async () => {
  try {
    const auth_user_url = `${API_URL}/user/profile`;
    const response = await axios.get(auth_user_url);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const changeuserPassword = async (obj) => {
  try {
      const url = `${API_URL}/user/changepassword`;
      const response = await axios.post(url,obj);
      return response;
  }
  catch (error) {
      throw error.response.data.error;
  }
};

export const updateUserDetails = async (obj) => {
  try {
    const url = `${API_URL}/user/updateUserDetails`;
    const response = await axios.post(url, obj);
    return response;
  } catch (error) {
    throw error.response;
  }
};

export const uploadProfilePicture = async (obj) => {
  try {
      const url = `${API_URL}/user/uploadprofilepic`;
      const response = await axios.post(url,obj);
      return response;
  }
  catch (error) {
      throw error.response.data.error;
  }
};

export const validateReCaptcha = async (token) => {
  try{
    const url = `${API_URL}/user/validate_reCaptcha`;
    const response = await axios.post(url, {token});
    return response;
  } catch(error) {
    return error.response;
  }
}