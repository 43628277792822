import { useParams, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Box, Button, Card, CardContent, Divider, Grid, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { connect } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import { updateEmployeeProfileDetail, getEmployeesProfileDetail } from "../../redux/actions/employee";

const EmployeeProfile = ({
    getEmployeesProfileDetail,
    employeeProfileDetail,
    updateEmployeeProfileDetail
}) => {
    const { employeeId } = useParams();
    const API_URL = localStorage.getItem('REACT_APP_API_URL') || process.env.REACT_APP_API_URL;

    const [name, setName] = useState("");
    const [department, setDepartment] = useState("");
    const [email, setEmail] = useState("");
    const [mobile, setMobile] = useState("");
    const [exp, setExp] = useState("");
    const [relExp, setRelExp] = useState("");
    const [technology, setTechnology] = useState("");
    const [skills, setSkills] = useState([]);

    const [isEdit, setIsEdit] = useState(false);
    const [searchParams] = useSearchParams();
    const edit = searchParams.get("edit");

    useEffect(() => {
        getEmployeesProfileDetail(employeeId);
    }, [employeeId]);

    useEffect(() => {
        if (employeeProfileDetail && employeeProfileDetail.employee) {
            setName(employeeProfileDetail.employee.u_name);
            setEmail(employeeProfileDetail.employee.u_email);
            setMobile(employeeProfileDetail.employee.u_mobile);
            setDepartment(employeeProfileDetail.employee.se_department);
            setExp(employeeProfileDetail.employee.se_overall_experience);
            setRelExp(employeeProfileDetail.employee.se_relevant_experience);
            setTechnology(employeeProfileDetail.employee.se_technology);
        }
        if (employeeProfileDetail && employeeProfileDetail.skills && employeeProfileDetail.skills.length > 0) {
            setSkills(employeeProfileDetail.skills);
        }
    }, [employeeProfileDetail]);

    useEffect(() => {
        if (edit) {
            setIsEdit(true);
        }
    }, [edit]);

    const updateInfo = async () => {
        let obj = {
          name,
          department,
          email,
          mobile,
          exp,
          relExp,
          technology
        };
        try{
            await updateEmployeeProfileDetail(obj, employeeId);
        } catch(error) {
            console.error(error);
        }
        
        setIsEdit(false);
      };

    return (
        <>
            <Box sx={{ mt: 1 }}>
                <Card xs={12} sx={{ mt: 1 }}>
                    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography variant="h5" sx={{ ml: 2, mt: 2 }}>
                            Employee Details
                        </Typography>
                        {isEdit ? (
                            <div>
                                <Button
                                    variant="text"
                                    size={"small"}
                                    onClick={() => setIsEdit(false)}
                                    sx={{ mt: 1, color: "red" }}
                                >
                                    <CloseIcon /> Cancel
                                </Button>
                                <Button
                                    variant="text"
                                    size={"small"}
                                    onClick={() => updateInfo()}
                                    sx={{ mr: 2, mt: 1, color: "green" }}
                                >
                                    <SaveIcon /> Save
                                </Button>
                            </div>
                        ) : (
                            <Button
                                variant="text"
                                color="primary"
                                size={"small"}
                                onClick={() => setIsEdit(true)}
                                sx={{ mr: 2, mt: 1 }}
                            >
                                <EditIcon /> Edit
                            </Button>
                        )}

                    </Box>
                    <Divider />
                    <Grid container spacing={2} sx={{ p: 2 }}>
                        <Grid item xs={4} sm={2}>
                            <Typography variant="subtitle2" fontSize={14}>
                                Name :
                            </Typography>
                        </Grid>
                        <Grid item xs={8} sm={4}>
                            {isEdit ? (
                                <TextField
                                    name="name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    disabled={!isEdit}
                                />
                            ) : (
                                <Typography variant="subtitle1" fontSize={14}>
                                    {" "}
                                    {name}
                                </Typography>
                            )}
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <Typography variant="subtitle2" fontSize={14}>
                                Department :
                            </Typography>
                        </Grid>
                        <Grid item xs={8} sm={4}>
                            {isEdit ? (
                                <TextField
                                    name="department"
                                    value={department}
                                    onChange={(e) => setDepartment(e.target.value)}
                                    disabled={!isEdit}
                                />
                            ) : (
                                <Typography variant="subtitle1" fontSize={14}>
                                    {" "}
                                    {department}
                                </Typography>
                            )}
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <Typography variant="subtitle2" fontSize={14}>
                                Email :
                            </Typography>
                        </Grid>
                        <Grid item xs={8} sm={4}>
                            {isEdit ? (
                                <TextField
                                    name="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    disabled={!isEdit}
                                />
                            ) : (
                                <Typography variant="subtitle1" fontSize={14}>
                                    {email}
                                </Typography>
                            )}
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <Typography variant="subtitle2" fontSize={14}>
                                Technology :
                            </Typography>
                        </Grid>
                        <Grid item xs={8} sm={4}>
                            {isEdit ? (
                                <TextField
                                    name="technology"
                                    value={technology}
                                    onChange={(e) => setTechnology(e.target.value)}
                                    disabled={!isEdit}
                                />
                            ) : (
                                <Typography variant="subtitle1" fontSize={14}>
                                    {technology}
                                </Typography>
                            )}
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <Typography variant="subtitle2" fontSize={14}>
                                Mobile :
                            </Typography>
                        </Grid>
                        <Grid item xs={8} sm={4}>
                            {isEdit ? (
                                <TextField
                                    name="mobile"
                                    value={mobile}
                                    onChange={(e) => setMobile(e.target.value)}
                                    disabled={!isEdit}
                                />
                            ) : (
                                <Typography variant="subtitle1" fontSize={14}>
                                    {mobile}
                                </Typography>
                            )}
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <Typography variant="subtitle2" fontSize={14}>
                                Total Experience :
                            </Typography>
                        </Grid>
                        <Grid item xs={2} sm={4}>
                            {isEdit ? (
                                <TextField
                                    name="exp"
                                    value={exp}
                                    onChange={(e) => setExp(e.target.value)}
                                    disabled={!isEdit}
                                />
                            ) : (
                                <Typography variant="subtitle1" fontSize={14}>
                                    {exp}
                                </Typography>
                            )}
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <Typography variant="subtitle2" fontSize={14}>
                                Relevant Experience :
                            </Typography>
                        </Grid>
                        <Grid item xs={2} sm={4}>
                            {isEdit ? (
                                <TextField
                                    name="relExp"
                                    value={relExp}
                                    onChange={(e) => setRelExp(e.target.value)}
                                    disabled={!isEdit}
                                />
                            ) : (
                                <Typography variant="subtitle1" fontSize={14}>
                                    {relExp}
                                </Typography>
                            )}
                        </Grid>
                    </Grid>
                </Card>
                <Card>
                    <Grid container spacing={2}>
                        <Grid item xs={6} sm={6}>
                            <Typography variant={"h5"} sx={{mt:2, ml:2}}>
                                Skills
                            </Typography>
                        </Grid>
                    </Grid>

                    <Divider />
                    <CardContent>
                        <div style={{display: "flex" }}>
                            <div style={{ flex: 1 }}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Skill</TableCell>
                                            <TableCell>Skill Gap</TableCell>
                                            <TableCell>Score</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {skills?.map((skill, index) => (
                                            <TableRow key={index}>
                                                <TableCell>{skill.name}</TableCell>
                                                <TableCell>{skill.gap}</TableCell>
                                                <TableCell>{skill.score}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </div>
                        </div>
                    </CardContent>
                </Card>
            </Box>
        </>
    )
};

const mapStateToProps = (state) => ({
    employeeProfileDetail: state.employee.employeeProfileDetail
});

const mapDispatchToProps = {
    getEmployeesProfileDetail,
    updateEmployeeProfileDetail
};

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeProfile);