import axios from "axios";

const API_URL = localStorage.getItem('REACT_APP_API_URL') || process.env.REACT_APP_API_URL;

export const getSkills = async (data) => {
    try {
      const url = `${API_URL}/skills/getAllSkills`;
      const response = await axios.get(url, data);
      return response;
    } catch (error) {
      throw error;
    }
};